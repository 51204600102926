
import "./Projects.css";
import "../GlobalCSS/BaseFloor.css";

import ContentBoxx from '../ContentBox1/ContentBox';
import ContactUs from "../Contact_Us/ContactUs";

import FillerImage from "../FillerImage/FillerImage";

import { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import { useRef } from "react";


import { CansatProject, Flair, Pinaka, Sparc, Vajra } from '../Projects/ProjectsImages';


import cansat1 from '../Resources/projectImages/cansatcomp1.jpg'
import flair1 from '../Resources/projectImages/flaircomp1.jpg'
import pinaka1 from '../Resources/projectImages/pinakacomp1.jpeg'
import sparc1 from '../Resources/projectImages/sparccomp1.jpg'
import vajra1 from '../Resources/projectImages/vajracomp1.jpg'


import ProjectsContact1 from "../Resources/Projects_Contact2.jpeg";
import ProjectsContact2 from "../Resources/Projects_Contact1.jpeg";

function Projects(props) {
  const cntntsize = "1.25vmax";

  const scrollToCANSAT = useRef();
  const scrollToSACup = useRef();

  const ExpandPictures = (controls, scrollId) => {
    if (
      document.getElementById(controls).className ==
      "WholePage openExtendedGallery"
    ) {
      scrollToCANSAT.current.scrollIntoView();
      document.getElementById(controls).className =
        "WholePage closeExtendedGallery";
      setTimeout(() => {
        document.getElementById(controls).style.display = "none";
      }, 300);
    } else {
      document.getElementById(controls).className =
        "WholePage openExtendedGallery";
      document.getElementById(controls).style.display = "block";
      scrollId.current.scrollIntoView();
    }
  };

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <div className="ProjectsBody">
      {/* <div className="ProjectsWrapper"> */}
      <div className="PageWrapper">
        <div
          //className="WholePage"
          id="TitleContainer"
          //style={{ height: "100vh" }}
        >
          <h1
            data-aos="fade-in"
            id="TitleText"
            style={{ marginBlock: "auto", color: props.color }}
          >
            PROJECTS
          </h1>
        </div>

        <div className="ProjectsWholePage" style={{ paddingTop: '10vh', paddingLeft: '6vw', paddingRight: '6vw' }}>
          <div data-aos="fade-right">
            <ContentBoxx
              giveid="vajra"
              heading="VAJRA"
              aspectRatio= "13/11"
              detailsId="projects#seemoreBox-cansatProjectdetails"
              activeimg={vajra1}
              activealt="ex1"
              carouselItemsImages={Vajra}
              text="Vajra was our stellar creation for the 2023 Spaceport America Cup! This dynamic sounding rocket stood 6.3ft tall and competed fiercely in the 10k COTS category. Our dynamic design made it no ordinary contender in the competition. It was armed with a 9-pound payload, our cutting-edge SPARC flight computer, and a unique black powder recovery system. Powered by the formidable Aerotech M2050X-P Solid Rocket Motor, Vajra is a testament to innovation in every aspect!"
              seemoretext="See More"
              detailsHeading="DETAILS"
              detailsText="After being selected through the difficult preliminary stages, the top 40 university teams from around the world compete, design, and launch their CanSat prototypes. For CanSat’22 Team Sammard placed 7th worldwide while in competition with other veteran teams. While developing our SRAD material, we are preparing to participate in CanSat’23."
              color="blue"
            />
          </div>
        </div>
        <div className="ProjectsWholePage" style={{ paddingTop: '10vh', paddingLeft: '6vw', paddingRight: '6vw' }}>
          <div data-aos="fade-right">
            <ContentBoxx
              giveid="pinaka"
              heading="PINAKA"
              // detailsId="projects#seemoreBox-pinakadetails"
              activeimg={pinaka1}
              activealt="ex1"
              // aspectRatio= "13/14"
              carouselItemsImages={Pinaka}
              text="PINAKA was our statement for the realm of innovation and science.
              It was tailored for the Spaceport America Cup 2022 to compete in the cutthroat 30k COTS category. Its composite design, blending carbon fibre and transparent glass fibre, is a marvel to behold. Pinaka housed a Solid Rocket Motor and our cutting-edge SPARC flight controller, but the feature that truly set it apart was our exclusive in-house recovery mechanism."
              // seemoretext="See More"
              // detailsHeading="DETAILS"
              // detailsText=" Pinaka was more than just a rocket for us, it's a testament to our commitment to pushing the boundaries of what's possible in the world of space exploration."
              // color="blue"
            />
          </div>
        </div>
        <div className="ProjectsWholePage" style={{ paddingTop: '10vh', paddingLeft: '6vw', paddingRight: '6vw' }}>
          <div data-aos="fade-right">
            <ContentBoxx
              giveid="cansat2022"
              heading="CANSAT 2022"
              detailsId="projects#seemoreBox-cansat2022details"
              activeimg={cansat1}
              activealt="ex1"
              // aspectRatio= "13/14"
              carouselItemsImages={CansatProject}
              text="Our CANSAT was a state-of-the-art project catering to the requirements of the CANSAT 2022 competition. Our payload was nothing short of a pinnacle of innovation. Launched to heights of 725m, it deployed its payload at 300m to reveal the innovation at its core, a cutting-edge gimbal, paired with an action camera, capturing every moment from unique angles. "
              seemoretext="See More"
              detailsHeading="DETAILS"
              detailsText="It was powered by a sophisticated flight computer and our payload autonomously managed subsystems during its flight using the data obtained from its onboard sensors. This stunning design helped us achieve rank 7 worldwide in the competition."
              color="blue"
            />
          </div>
        </div>
        <div className="ProjectsWholePage" style={{ paddingTop: '10vh', paddingLeft: '6vw', paddingRight: '6vw' }}>
          <div data-aos="fade-right">
            <ContentBoxx
              giveid="sparc"
              heading="SPARC"
              detailsId="projects#seemoreBox-sparcdetails"
              activeimg={sparc1}
              // aspectRatio= "13/15"
              activealt="ex1"
              carouselItemsImages={Sparc}
              text="Introducing Sparc 3.0 (Sammard Programmable Advance Rocket Computer), our cutting-edge flight computer designed for the SRAD GPS component. It is equipped with a reliable NEO-6M GPS module, a 6-axis IMU, and a barometric pressure sensor. Sparc 3.0 meticulously captures rocket dynamics and sends real-time data transmission to the Ground Station ensuring mission oversight by our team."
              seemoretext="See More"
              detailsHeading="DETAILS"
              detailsText="During its flight, all the data is archived onto a micro SD card which enables us to perform a thorough analysis of the flight. With Sparc 3.0, we boldly conquer competitions with precision and confidence. It is our trusted companion in the quest for exploration and discovery."
              color="blue"

            />
          </div>
        </div>
        {/* <div className="ProjectsWholePage" style={{ paddingTop: '10vh', paddingLeft: '6vw', paddingRight: '6vw' }}>
          <div data-aos="fade-right">
            <ContentBoxx
              giveid="flair"
              heading="FLAIR"
              activeimg={flair1}
              activealt="ex1"
              aspectRatio= "21/12"
              carouselItemsImages={Flair}
              text="Flair was our sleek graphical user interface designed to keep a close eye on our rocket's every move as it embarked on its epic journey into the unknown. Flair with its visually stunning design, keeps one visually immersed in the thrilling launch unfolding before their eyes. Crafted using the power of PyQt5, MathPlotLib, and a touch of Embedded C magic (courtesy of Arduino), Flair brought out vital rocket stats like altitude, speed, pressure, and GPS location, to life. It's a front-row seat to the wonders of space exploration, all wrapped up in a user-friendly interface that's both captivating and informative."
            />
          </div>
        </div> */}
        
        <div className='ProjectsWholePage'>
          <div data-aos="fade-right" className='PictureBox' >
            <FillerImage
              src={ProjectsContact1}
              alt="Blank"
            />
            <FillerImage
              src={ProjectsContact2}
              alt="Blank"
            />
          </div>
          <div className='Address'>
            <ContactUs />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Projects;
