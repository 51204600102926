import imag2 from "../Resources/PrevYrCANSAT.jpeg";
import imag4 from '../Resources/2022/Testing22_2.jpeg';

const  Cansat= [
    {
      id: 1,
      src: imag2,
      alt: "ex2"
    }
];
const  SaCup= [
    {
      id: 1,
      src: imag4,
      alt: "ex2"
    }
];
export { Cansat, SaCup };