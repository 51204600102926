import './Timeline.css';
import ContactUs from '../Contact_Us/ContactUs';
import { useEffect } from 'react';
import Aos from 'aos';
import "aos/dist/aos.css";

import TLPattern from '../Resources/Timelinebackground.jpg';
import TLPatternRev from '../Resources/Timelinetop.png';
import TLPatternRevTrans from '../Resources/Timeline_pattern_reverse_transparent.png'
import DarkTimelineImg from '../Resources/Timeline_dark.png';
import LightTimelineImg from '../Resources/Timeline_light.png';
import TimelineImage from '../Resources/Timeline/Image1.png';
import imag1 from '../Resources/Timeline/Image Frame-1.png';
import imag2 from '../Resources/Timeline/Image Frame-2.png';
import imag3 from '../Resources/Timeline/Image Frame-3.png';
import imag4 from '../Resources/Timeline/Image Frame-4.png';
import imag5 from '../Resources/Timeline/Image Frame-5.png';
import imag6 from '../Resources/Timeline/Image Frame-6.png';
import imag7 from '../Resources/Timeline/Image Frame-7.png';
import imag8 from '../Resources/Timeline/Image Frame-8.png';
import imag9 from '../Resources/Timeline/Image Frame-9.png';
import imag from '../Resources/Timeline/Image Frame.png';

import { color } from 'three/examples/jsm/nodes/shadernode/ShaderNode';

function Timeline(props) {
    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);

    return (
        <>
            <div id='TimelineContainer' style={{ paddingTop: '7vh', display: 'flex', flexDirection: 'column', msFlexDirection: 'column', WebkitFlexDirection: 'column', justifyContent: 'space-between', WebkitJustifyContent: 'space-between', height: '100vh', backgroundColor: props.darkmode ? '#000505' : 'white' }}>
                <img src={TLPatternRev} alt="Timeline" style={{ width: '100vw', paddingTop: '2vh' }} />
                {/* <h1 style={{color: 'white'}}>Timeline</h1> */}
                <h1 data-aos="fade-right" id='TitleText' style={{ marginInline: 'auto', color: props.darkmode ? 'white' : '#252525' }}>TIMELINE</h1>
                <img src={TLPattern} alt="Timeline" style={{ width: '100vw' }} />
            </div>
            <div className='timeline-background'>
                <div className='timeline-imagebox'>
                    {/* <div className='timeline-image-margin'>
                        <img src={props.darkmode ? TimelineImage : TimelineImage} alt="Timeline" className='timeline-image' />
                    </div> */}
                    <div class="timeline-grid">
                    <div class="first">
                        <table id="first">
                        <tr>
                            <td><img src={imag1}id="image"alt="imag0" /></td>
                        </tr>
                        <tr>
                            <td><img src={imag4}id="image1"alt="imag1"/></td>
                        </tr>
                        <tr>
                            <td><img src={imag5}id="image2"alt="imag2"/></td>
                        </tr>
                        <tr>
                            <td><img src={imag6}id="image3"alt="imag3"/></td>
                        </tr>
                        <tr>
                            <td><img src={imag8}id="image4"alt="imag4"/></td>
                        </tr>
                        </table>
                    </div>
                    <div id="middle">
                        <table>
                        <tr>
                            <td><img src={TimelineImage}id="vector"/></td>
                        </tr>
                        </table>
                    </div>
                    <div class="second">
                        <table id="second">
                        <tr>
                            <td><img src={imag3}id="image5"alt="imag5"/></td>
                        </tr>
                        <tr>
                            <td><img src={imag7}id="image6"alt="imag6"/></td>
                        </tr>
                        <tr>
                            <td><img src={imag}id="image7"alt="imag7"/></td>
                        </tr>
                        <tr>
                            <td><img src={imag9}id="image8"alt="imag8"/></td>
                        </tr>
                        <tr>
                            <td><img src={imag2}id="image9"alt="imag9"/></td>
                        </tr>
                        </table>
                    </div>
                    </div>
                    <div className='class1'>
                    </div>
                    <div className='class2'>
                    </div>
                    <div className='class3'>
                    </div>
                </div>
            </div>
            <div style={{ backgroundColor: "#252525" }}>
                <ContactUs />
            </div>
        </>
    )

}
export default Timeline;