import './Home.css';
import ContentBoxx from "../ContentBox1/ContentBox";
import ContactUs from '../Contact_Us/ContactUs';
import { useEffect } from 'react';
import Aos from 'aos';
import "aos/dist/aos.css";
import { HomeAbout } from '../Home/HomeCarousel';
import ProjectBox from '../ProjectBox/ProjectBox.jsx';
import EventsBox from '../EventsBox/EventBox.jsx';


import TeamPicMain from '../Resources/Home_main.jpeg';
import Pinaka from '../Resources/exploded pinaka.png';
import Cansat from '../Resources/exploaded cansat.jpg';
import Home_Contact1 from '../Resources/Home_ContactUs1.JPG';
import Home_Contact2 from '../Resources/Home_ContactUs2.JPG';
import { EventsPics } from '../EventsBox/EventsPics.js';
import FillerImage from '../FillerImage/FillerImage.jsx';




function Home(props) {
    const cntntsize = "1.45vmax";
    const eventswidth = "30vh";

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);

    return (
        <>
            <div className='HomeWrapper' style={{ color: props.color }}>
                <div className='WholePage' id='TitleContainer' style={{ height: '100vh' }}>
                    <h1 data-aos="fade-in" id='TitleText' style={{ marginBlock: 'auto', color: props.color }}>TEAM SAMMARD</h1>
                </div>
                <div className='WholePage' id='TitleContainer' style={{ height: '100vh' }}>
                    <h1 data-aos="fade-in" id='TitleText' style={{ marginBlock: 'auto', marginInline: '2%', color: props.color }}>"GIVE YOUR DREAMS SOME SPACE TO UNFOLD"</h1>
                </div>
                <div className='HomeWholePage' style={{ paddingTop: '10vh', paddingLeft: '6vw', paddingRight: '6vw' }}>
                    <div data-aos="fade-right">
                        <ContentBoxx
                            giveid="AboutUs"
                            heading="ABOUT US"
                            activeimg={TeamPicMain}
                            activealt="ex1"
                            carouselItemsImages={HomeAbout}
                            text="Team Sammard provides a platform for space enthusiasts to channel their passion and showcase their skills. Team Sammard participates in two major competitions- the American Astronautical Society (AAS) and NASA's annual rocket science competition CanSat and Spaceport America Cup(SAC). The multidisciplinary nature of the team brings together the best of minds across various fields to ensure we all can touch the skies ourselves."
                            link='/about-us'
                            colour={props.color}
                            linkText="Know More About Us"
                        />
                    </div>
                </div>
                <div className='HomeWholePage' style={{ paddingTop: '8vh' }}>
                    <h1 data-aos="fade-right" style={{ paddingBottom: '0', textAlign: 'center', fontSize: '7.75vmin', color: props.color, fontFamily: 'xspace' }}>PROJECTS</h1>
                    <div data-aos="fade-right" className='SponsorsView' style={{ width: '100vw', display: 'flex', gap: '1vmin', WebkitJustifyContent: 'space-evenly', justifyContent: 'space-evenly', paddingBottom: '5vw', paddingInline: '3vw', paddingTop: '0' }}>
                        <div className='projFlex'>
                            <ProjectBox
                                heading="CANSAT"
                                src={Cansat}
                                alt="ex1"
                                pathid="cansat"
                            />
                            <ProjectBox
                                heading="SA CUP"
                                src={Pinaka}
                                alt="ex1"
                                pathid="sacup"
                            />
                        </div>
                    </div>
                </div>
                <div className='HomeWholePage' style={{ paddingTop: '5vw', paddingLeft: '6vw', paddingRight: '6vw' }}>
                    <div data-aos="fade-right">

                        <EventsBox
                            heading="EVENTS"
                            List={EventsPics}
                        />
                    </div>
                </div>
                <div className='BasePage'>
                    <div data-aos="fade-right" className='PictureBox'>
                        <FillerImage
                            src={Home_Contact1}
                            alt="Blank"
                        />
                        <FillerImage
                            src={Home_Contact2}
                            alt="Blank"
                        />
                    </div>
                    <div className='Address'>
                        <ContactUs />
                    </div>
                </div>
            </div>
        </>
    );
}

export default Home;