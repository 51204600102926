import imag2 from "../Resources/PrevYrCANSATexhibtoschoolstudents.jpeg";
import imag5 from "../Resources/PrevYrCANSATexhibition.jpeg";

const ourMissionImages = [
    {
      id: 1,
      src: imag2,
      alt: "ex2"
    }
];
const ourVisionImages = [
    {
      id: 1,
      src: imag5,
      alt: "ex2"
    }
];
export { ourMissionImages, ourVisionImages };