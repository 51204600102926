import imag1 from '../Resources/projectImages/cansatcomp1.jpg'
import imag2 from '../Resources/projectImages/cansatcomp2.jpg'
import imag3 from '../Resources/projectImages/cansatcomp3.jpg'
import imag4 from '../Resources/projectImages/flaircomp2.png'
import imag5 from '../Resources/projectImages/flairccomp3.png'
import imag6 from '../Resources/projectImages/flairomp4.png'
import imag7 from '../Resources/projectImages/flairomp5.png'
import imag8 from '../Resources/projectImages/pinakacomp2.jpg'
import imag9 from '../Resources/projectImages/pinakacomp3.jpg'
import imag10 from '../Resources/projectImages/sparccomp2.jpg'
import imag11 from '../Resources/projectImages/sparccomp3.jpg'
import imag12 from '../Resources/projectImages/vajracomp2.jpg'
import imag13 from '../Resources/projectImages/vajra_comp3.jpg'


const  CansatProject= [
    {
      id: 1,
      src: imag2,
      alt: "ex2"
    },
    {
      id: 2,
      src: imag3,
      alt: "ex3"
    }
];
const Flair=[
  {
    id: 3,
    src: imag4,
    alt: "ex3"
  },
  {
    id: 4,
    src: imag5,
    alt: "ex3"
  },
  {
    id: 5,
    src: imag6,
    alt: "ex3"
  },
  {
    id: 6,
    src: imag7,
    alt: "ex3"
  }
];

const Pinaka=[
  {
    id: 7,
    src: imag8,
    alt: "ex3", 
  },
  {
    id: 8,
    src: imag9,
    alt: "ex3"
  }
];

const Sparc=[
  {
    id: 9,
    src: imag10,
    alt: "ex3"
  },
  {
    id: 10,
    src: imag11,
    alt: "ex3"
  }
];

const Vajra=[
  {
    id: 11,
    src: imag12,
    alt: "ex3"
  },
  {
    id: 12,
    src: imag13,
    alt: "ex3"
  }
];
export { CansatProject, Flair, Pinaka, Sparc, Vajra };